import React, { useState } from "react";
import ReactMapGl, { Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

export default function Maps() {
  const [viewport, setViewport] = useState({
    latitude: 23.0338,
    longitude: 72.585,
    zoom: 10,
  });

  return (
    <ReactMapGl
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      initialViewState={viewport}
      mapStyle="mapbox://styles/mapbox/streets-v9"
      onViewPortChange={(viewport) => setViewport(viewport)}
      className="map"
    >
      <Marker latitude={23.013629} longitude={72.459769}>
        <img className="w-8" src="./images/red-dot.png" alt="Pin" />
      </Marker>
      <Marker latitude={23.045726} longitude={72.499419}>
        <img className="w-8" src="./images/blue-dot.png" alt="Pin" />
      </Marker>
      <Marker latitude={23.135955} longitude={72.549932}>
        <img className="w-8" src="./images/yellow-dot.png" alt="Pin" />
      </Marker>
      <Marker latitude={22.486443} longitude={70.056285}>
        <img className="w-8" src="./images/pink-dot.png" alt="Pin" />
      </Marker>
    </ReactMapGl>
  );
}
