import { collection, addDoc } from "firebase/firestore";
import { db } from "../lib/init-firebase";

import React from "react";

const Partner = () => {
  const date = new Date().valueOf();

  const [partnerDetails, setPartnerDetails] = React.useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    city: "",
    message: "",
    today: date,
  });

  function handleChange(event) {
    const { name, value } = event.target;
    setPartnerDetails((prevPartnerDetails) => ({
      ...prevPartnerDetails,
      [name]: value,
    }));
  }

  const postData = async (e) => {
    e.preventDefault();
    const { fname, lname, email, phone, city, message, today } = partnerDetails;

    const partnerDetailsRef = collection(db, "partner-details");

    if (fname && lname && email && phone && city && message) {
      addDoc(partnerDetailsRef, {
        fname,
        lname,
        email,
        phone,
        city,
        message,
        today,
      })
        .then((res) => {
          console.log(res.id);
          alert(
            "Thanks for sharing the details! \nOur Team will get back to you shortly."
          );
          setPartnerDetails({
            fname: "",
            lname: "",
            email: "",
            phone: "",
            city: "",
            message: "",
            today: 0,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert("Please fill all the details !!");
    }
  };

  return (
    <div id="partner" className="mt-[-50px] md:mt-0">
      <section className="partner">
        <h1 className="text-3xl md:text-6xl lg:text-8xl">Become a Partner</h1>
        <p className="text-l md:text-2xl lg:text-3xl">
          We think it’s time to shake paws! Partner with Pupparazzi and enjoy
          several benefits!
        </p>
        <p className="text-l md:text-2xl lg:text-3xl">
          You grow, we grow. It's an even trade!
        </p>
        <img
          className="mt-[100px] w-[350px] md:w-[450px] lg:w-[550px]"
          src="./images/partner-bg.png"
          alt="Background"
        />
        <div className="flex items-center justify-around flex-col md:flex-col lg:flex-row mx-[20px] md:mx-[40px] lg:mx-[60px] mt-[-400px] md:mt-[-500px] lg:mt-[-600px] partner-content">
          <div className=" partner-img">
            <img
              className="mt-[100px] w-[250px] md:w-[350px] lg:w-[400px] dog-img"
              src="./images/partner-dog-img.png"
              alt="Background"
            />
          </div>
          <div className="md:mt-[-50px] lg:mt-[150px] mb-[100px] partner-form">
            <div className=" h-[550px] w-[350px] md:h-[550px] md:w-[550px] lg:h-[600px] lg:w-[600px] form-outter">
              <form
                className="partner-form text-xs md:text-sm lg:text-base form-div mt-[30px] mx-[20px]"
                action=""
              >
                <div className="form-row ">
                  <div className="form-col">
                    <label>First Name</label>
                    <input
                      className="w-[100px] md:w-[150px] lg:w-[200px]"
                      type="text"
                      name="fname"
                      value={partnerDetails.fname}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-col">
                    <label>Last Name</label>
                    <input
                      className="w-[100px] md:w-[150px] lg:w-[200px]"
                      type="text"
                      name="lname"
                      value={partnerDetails.lname}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-col">
                    <label>Email</label>
                    <input
                      className="w-[100px] md:w-[150px] lg:w-[200px]"
                      type="email"
                      name="email"
                      value={partnerDetails.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-col">
                    <label>Phone Number</label>
                    <input
                      className="w-[100px] md:w-[150px] lg:w-[200px]"
                      type="number"
                      name="phone"
                      value={partnerDetails.phone}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-row ">
                  <div className="form-col ">
                    <label>City</label>
                    <input
                      className="w-[100px] md:w-[150px] lg:w-[200px]"
                      type="text"
                      name="city"
                      value={partnerDetails.city}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-col">
                    <label>
                      Tell us why would you make a great franchise for
                      Pupparazzi?
                    </label>
                    <textarea
                      cols="30"
                      rows="5"
                      name="message"
                      value={partnerDetails.message}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
                <div className="mt-8 form-btn">
                  <button type="submit" onClick={postData}>
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Partner;
