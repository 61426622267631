import React from "react";

import { NavLink } from "react-router-dom";
function Client() {
  return (
    <div>
      <h1 className="text-center text-4xl">Click to View Data</h1>
      <div className="m-10">
        <NavLink to="/grooming">
          <h4 className="text-2xl mb-5">1. Grooming Form Details</h4>
        </NavLink>
        <NavLink to="/partner">
          <h4 className="text-2xl mb-5">2. Franchise Form Details</h4>
        </NavLink>
        <NavLink to="/training">
          <h4 className="text-2xl mb-5">3. Training Form Details</h4>
        </NavLink>
        <NavLink to="/contact">
          <h4 className="text-2xl mb-5">4. Contact Form Details</h4>
        </NavLink>
        <NavLink to="/swimming">
          <h4 className="text-2xl mb-5">5. Swimming Form Details</h4>
        </NavLink>
        <NavLink to="/party">
          <h4 className="text-2xl mb-5">6. Parties Form Details</h4>
        </NavLink>
        <NavLink to="/boarding">
          <h4 className="text-2xl mb-5">7. Boarding Form Details</h4>
        </NavLink>
      </div>
    </div>
  );
}

export default Client;
