import React from "react";

const Quote = () => {
  return (
    <div>
      <section className="mb-24 md:mb-36 lg:mb-40">
        <blockquote className="quote italic font-semibold  mx-10 md:mx-20 lg:mx-48 mt-16">
          <p
            className="text-xl
        md:text-2xl lg:text-4xl"
          >
            Pupparazzi is not just a pet shop, it is an experience in itself!
            With a community of over 20,000 pets & pet pawrents, we are
            Gujarat’s biggest multi-facility pet club providing a wholesome and
            fulfilling experience to your little friends!
          </p>
        </blockquote>
        <img
          className="w-20 md:w-36 lg:w-45 float-left mt-[-270px] md:mt-[-180px] lg:mt-[-200px] ml-4 md:ml-16 lg:ml-36"
          src="./images/quote-left.svg"
          alt="Background"
        />
        <img
          className="w-20 md:w-36 lg:w-45 float-right mr-4 md:mr-16 lg:mr-36  mt-[-50px] md:mt-[-70px] lg:mt-[-50px]"
          src="./images/quote-right.svg"
          alt="Background"
        />
      </section>
      {/* -------------- */}
      <div className="flex justify-between  mx-2 md:mx-5 lg:mx-10 mt-[-50px]">
        <div className="dog-left ">
          <img
            className="w-32 md:w-48 lg:w-72 "
            src="./images/quote-dog1.png"
            alt="Background"
          />
        </div>
        <div className="dog-right flex flex-row ">
          <img
            className="w-16 md:w-32 lg:w-48 mr-2 "
            src="./images/quote-dog2.png"
            alt="Background"
          />
          <img
            className="w-16 md:w-32 lg:w-48 "
            src="./images/quote-dog3.png"
            alt="Background"
          />
        </div>
      </div>
    </div>
  );
};

export default Quote;
