import React from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../lib/init-firebase";
import { Carousel } from "flowbite-react";

function Training({ closeModal }) {
  const date = new Date().valueOf();
  const [trainingForm, setTrainingForm] = React.useState({
    fname: "",
    email: "",
    phone: "",
    training: "",
    breed: "",
    today: date,
  });
  function handleChange(event) {
    const { name, value } = event.target;
    setTrainingForm((prevTraining) => ({
      ...prevTraining,
      [name]: value,
    }));
  }

  const postData = async (e) => {
    e.preventDefault();
    const { fname, email, phone, training, breed, today } = trainingForm;

    const trainingDetailsRef = collection(db, "training");

    if (fname && email && phone && training && breed) {
      addDoc(trainingDetailsRef, {
        fname,
        email,
        phone,
        training,
        breed,
        today,
      })
        .then((res) => {
          console.log(res.id);
          alert(
            "Thanks for sharing the details! \nOur Team will get back to you shortly."
          );
          setTrainingForm({
            fname: "",
            email: "",
            phone: "",
            trainingType: "",
            breed: "",
            today: 0,
          });
        })
        .catch((err) => {
          alert("Something went worng! \n Please try again after sometime.");
          console.log(err);
        });
    } else {
      alert("Please fill all the details !!");
    }
  };

  return (
    <div className="z-50 fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex items-center justify-center">
      <div className="relative bg-[#19bccd] flex flex-col tm-outer w-[350px] md:w-[750px] lg:w-[800px] h-[650px] md:h-[570px]">
        <button
          onClick={() => {
            closeModal(false);
          }}
        >
          <img
            className="absolute top-4 right-6 w-4 md:w-7"
            src="./images/tc.png"
            alt="Cancel Button"
          />
          <img
            className="rotate-[20deg] absolute bottom-40 md:bottom-10 right-2 md:right-8 w-20 md:w-32 lg:w-40"
            src="./images/tm-img.png"
            alt="Training Background"
          />
        </button>
        <div className="mt-[40px] md:m-[20px] flex items-center flex-col md:flex-row justify-center md:justify-start  tm-upper">
          <div className=" tm-img">
            <div className="h-[185px] w-[325px] md:h-[300px] md:w-[400px]">
              <Carousel
                indicators={false}
                slide={true}
                leftControl={
                  <img
                    className="w-7 opacity-70"
                    src="./images/left-c.png"
                    alt="Left"
                  ></img>
                }
                rightControl={
                  <img
                    className="w-7 opacity-75"
                    src="./images/right-c.png"
                    alt="Right"
                  ></img>
                }
              >
                <img
                  className="h-fit"
                  src="./images/Training/training-1.jpg"
                  alt="Training"
                />
                <img
                  className="h-fit"
                  src="./images/Training/training-2.jpg"
                  alt="Training"
                />
                <img
                  className="h-fit"
                  src="./images/Training/training-3.jpg"
                  alt="Training"
                />
                <img
                  className="h-fit"
                  src="./images/Training/training-4.jpg"
                  alt="Training"
                />
                <img
                  className="h-fit"
                  src="./images/Training/training-5.jpg"
                  alt="Training"
                />
                <img
                  className="h-fit"
                  src="./images/Training/training-6.jpg"
                  alt="Training"
                />
                <img
                  className="h-fit"
                  src="./images/Training/training-7.jpg"
                  alt="Training"
                />
              </Carousel>
            </div>
          </div>
          <div className="mt-1 md:mt-0 mx-auto md:mx-[20px] w-[300px] md:w-[430px] tm-text">
            <h1 className="text-2xl md:text-4xl lg:text-5xl text-left">
              Training
            </h1>
            <p className=" text-xs lg:text-sm mb-2 md:mb-5 text-justify">
              Sit, fetch, run.. We’re sure your cute little pet can do much more
              than that! Along with first-level training, Pupparazzi’s pet
              training service is designed to facilitate interaction and foster
              pet-parent relationships. We help in building your doggo’s
              confidence, so that they can become more playful and socialise
              better with other pets and hoomans.
            </p>
            <p className=" text-xs lg:text-sm mb-1 md:mb-3 text-justify">
              Our training sessions are divided into three categories-
            </p>
            <ul className="mt-[2px] ml-5 list-disc text-[#fff] text-sm">
              <li className="text-xs lg:text-sm">Puppy training</li>
              <li className="text-xs lg:text-sm">
                Basic obedience and behaviour training
              </li>
              <li className="text-xs lg:text-sm">Agility/sports training</li>
            </ul>
          </div>
        </div>
        <div className="mt-0 md:mt-[-20px] m-form">
          <form
            className="w-[325px] md:w-[550px] text-xs md:text-sm lg:text-base form-div"
            action=""
          >
            <div className="form-row">
              <div className="form-col">
                <input
                  placeholder="Name"
                  className="text-xs lg:text-sm h-[35px] w-[300px] md:h-[35px] md:w-[550px]"
                  type="text"
                  name="fname"
                  value={trainingForm.fname}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-col">
                <input
                  placeholder="Email"
                  className="text-xs lg:text-sm h-[35px] md:h-[35px] w-[135px] md:w-[260px]"
                  type="email"
                  name="email"
                  value={trainingForm.email}
                  onChange={handleChange}
                />
              </div>
              <div className="form-col">
                <input
                  placeholder="Phone Number"
                  className="text-xs lg:text-sm h-[35px] md:h-[35px] w-[135px] md:w-[260px]"
                  type="number"
                  name="phone"
                  value={trainingForm.phone}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-col">
                <select
                  className="text-xs lg:text-sm h-[35px] md:h-[35px] w-[135px] md:w-[260px]"
                  name="training"
                  value={trainingForm.training}
                  onChange={handleChange}
                >
                  <option value="Puppy training">Puppy training</option>
                  <option value="Basic obedience and behaviour training">
                    Basic obedience and behaviour training
                  </option>
                  <option value="Agility/sports training">
                    Agility/sports training
                  </option>
                  <option value="Advanced obedience and behaviour training">
                    Advanced obedience and behaviour training
                  </option>
                </select>
              </div>
              <div className="form-col">
                <input
                  placeholder="Dog Breed"
                  className="text-xs lg:text-sm h-[35px] md:h-[35px] w-[135px] md:w-[260px]"
                  type="text"
                  name="breed"
                  value={trainingForm.breed}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="bg-[#f8bf53] form-col m-btn">
                <button
                  className="text-base lg:text-lg w-[300px] h-[30px] md:w-[550px] md:h-[40px]"
                  type="submit"
                  onClick={postData}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Training;
