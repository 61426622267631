import React from "react";

function Rights() {
  return (
    <div>
      <div className="copy-right">
        <p className="text-base md:text-lg">
          © 2022 Pupparazzi. All Rights Reserved. Designed and Developed by{" "}
          <span>
            <a href="https://www.greymetaphor.com/">The Grey Metaphor</a>
          </span>
        </p>
      </div>
    </div>
  );
}

export default Rights;
