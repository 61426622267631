import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Rights from "./Rights";

function Privacy() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div id="privacy">
      <div id="header">
        <div className="flex flex-wrap py-2 navbar">
          <div className="w-full px-4 ">
            <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 rounded">
              <div className="container px-4 mx-auto flex  items-center justify-between">
                <div className="w-full relative flex justify-between lg:w-auto px-4 lg:static lg:block lg:justify-start">
                  {/* <a
                  className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-white"
                  href="#hero"
                >
                  <img src="./images/logo.png" alt="" />
                </a> */}
                  <NavLink
                    to="/"
                    className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-white"
                  >
                    <img src="./images/logo.png" alt="Logo" />
                  </NavLink>
                  {/* <button
                    className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                    type="button"
                    onClick={() => setMenuOpen(!menuOpen)}
                  >
                    <FaBars className="nb-fabars" />
                  </button> */}
                </div>
                <NavLink to="/" className="home-icon">
                  <img src="./images/privacy-home.svg" alt="HomeBtn" />
                </NavLink>
                {/* <div
                  className={
                    "lg:flex flex-grow items-center" +
                    (menuOpen ? " flex" : " hidden")
                  }
                  id="example-navbar-info"
                >
                  <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                    <li className="nav-item">
                      <a
                        className="px-3 py-2 flex items-center text-md uppercase font-bold leading-snug text-black
                      hover:text-[#ff6b63]"
                        href="#services"
                      >
                        Services
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="px-3 py-2 flex items-center text-md uppercase font-bold leading-snug text-black hover:text-[#ff6b63]"
                        href="#event"
                      >
                        Gallery
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="px-3 py-2 flex items-center text-md uppercase font-bold leading-snug text-black hover:text-[#ff6b63]"
                        href="#about"
                      >
                        About Us
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="px-3 py-2 flex items-center text-md uppercase font-bold leading-snug text-black hover:text-[#ff6b63]"
                        href="#partner"
                      >
                        Franchise
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="px-3 py-2 flex items-center text-md uppercase font-bold leading-snug text-black hover:text-[#ff6b63]"
                        href="#location"
                      >
                        Locations
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="px-3 py-2 flex items-center text-md uppercase font-bold leading-snug text-black hover:text-[#ff6b63]"
                        href="#contact"
                      >
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div className="privacy-header w-full h-[200px] flex items-center justify-between">
        <h1 className="ml-[50px] md:ml-[100px] text-5xl">Privacy Policy</h1>
        <img
          className="mr-[50px] md:mr-[100px] w-16 md:w-28"
          src="./images/privacy-policy.png"
          alt="Privacy Policy"
        />
      </div>
      <div className="flex items-center justify-center">
        <div className="mt-[100px] mb-[200px] privacy-content w-[700px]">
          <p className="px-10 text-base lg:text-lg text-justify">
            Pupparazzi is strongly committed to protecting the privacy of our
            subscribers. This privacy policy document contains information that
            you should go through so that you fully understand the terms
            involved in your subscribing to our service, and how we use the
            personal information submitted by you to avail of this service. When
            you register at Pupparazzi, we ask you to provide us with your
            personal information. Personal information means information that
            can be used to identify and contact you. We may also use that
            information to let you know about additional products and services
            in which you might be interested. We may ask you for information
            about your interests and preferences to serve you better, but it’s
            your choice to respond or not.
          </p>
          <p className="mt-5 px-10 text-base lg:text-lg text-justify">
            The User visiting Pupparazzi does so at their own discretion and
            agrees to give their informed consent prior registering with us.
            Your personal details (name, location, e-mail) will not be sold or
            rented by us to any third party without your consent. Though we
            reserve the right to communicate your personal information to any
            third party that makes a legally-compliant request for its
            disclosure; we will not disclose your name, address and other
            information which identifies you personally to any third party
            without your consent. However, we hold no responsibility for any
            action of third party that may have directed you to our website.
          </p>
        </div>
      </div>

      <footer className="flex items-center justify-between">
        <div className="p-7  h-full  footer-content1">
          <div className="flex justify-center items-center mb-3 footer-logo">
            <NavLink to="/">
              <img
                className="w-[100%]"
                src="./images/footer-logo2.png"
                alt="Logo"
              />
            </NavLink>
          </div>
          {/* <div className="footer-row">
            <div className="footer-col address">
              <img src="./images/pin.png" alt="" />
              <a
                href="https://maps.google.com/?cid=11204830985246932760&entry=gps"
                target="_blank"
              >
                <h3 className="w-[200px] md:w-[300px] text-xs md:text-base lg:text-lg">
                  Next Crossroad to Bharat Petroleum, VIP Rd, opp. Stanza, South
                  Bopal, Ahmedabad, Gujarat 380058
                </h3>
              </a>
            </div>
            <div className="footer-col phone">
              <img src="./images/phone.png" alt="" />
              <a href="tel:6358848177">
                <h3 className="w-[200px] md:w-[300px] text-xs md:text-base lg:text-lg">
                  63588 48177
                </h3>
              </a>
            </div>
            <div className="footer-col mail">
              <img src="./images/mail.png" alt="" />
              <a href="mailto:info@pupparazziclub.com">
                <h3 className="w-[200px] md:w-[300px] text-xs md:text-base lg:text-lg">
                  info@pupparazziclub.com
                </h3>
              </a>
            </div>
          </div>
          <div className="w-full footer-row">
            <div className="flex flex-col ">
              <div>
                <h2 className=" text-base md:text-lg lg:text-xl ">KNOW MORE</h2>
              </div>
              <div className="flex flex-row justify-between">
                <div>
                  <a href="#services">
                    <h3 className=" text-xs md:text-base lg:text-lg">
                      Services
                    </h3>
                  </a>
                </div>
                <div>
                  <a href="#event">
                    <h3 className="text-xs md:text-base lg:text-lg">Events</h3>
                  </a>
                </div>
                <div>
                  <a href="#about">
                    <h3 className="text-xs md:text-base lg:text-lg">About</h3>
                  </a>
                </div>
                <div>
                  <a href="#partner">
                    <h3 className="text-xs md:text-base lg:text-lg">
                      Franchise
                    </h3>
                  </a>
                </div>
                <div>
                  <a href="#location">
                    <h3 className="text-xs md:text-base lg:text-lg">
                      Location
                    </h3>
                  </a>
                </div>
                <div>
                  <a href="#contact">
                    <h3 className="text-xs md:text-base lg:text-lg">Contact</h3>
                  </a>
                </div>
              </div>
            </div>
          </div> */}
          <div className="w-[70%] footer-row ">
            <div className="flex flex-col">
              {/* <div>
                <h2 className="text-base md:text-lg lg:text-xl">INFORMATION</h2>
              </div>
              <div className="flex flex-row footer-col footer-info">
                <div>
                  <NavLink to="/privacy">
                    <h3 className="text-xs md:text-base lg:text-lg">
                      Privacy Policy
                    </h3>
                  </NavLink>
                </div>
                <div>
                  <NavLink to="/terms">
                    <h3 className="text-xs md:text-base lg:text-lg">
                      Terms of Use
                    </h3>
                  </NavLink>
                </div>
              </div> */}
              {/* <div className="w-[70%] footer-row">
                <h2 className="text-base md:text-lg lg:text-xl">FOLLOW</h2>
                <div className="footer-col">
                  <a
                    href="https://instagram.com/pupparazziclub?igshid=ZmVmZTY5ZGE="
                    target="_blank"
                  >
                    <img
                      className="w-[50%] md:w-[70%] lg:w-[100%]"
                      src="./images/insta.png"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://www.facebook.com/pupparazziclub/"
                    target="_blank"
                  >
                    <img
                      className="w-[50%] md:w-[70%] lg:w-[100%]"
                      src="./images/fb.png"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://youtube.com/@pupparazziclubofficial5072"
                    target="_blank"
                  >
                    <img
                      className="w-[50%] md:w-[70%] lg:w-[100%]"
                      src="./images/yt.png"
                      alt=""
                    />
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </footer>

      <div className="footer-pt w-full ">
        <Rights />
      </div>
    </div>
  );
}

export default Privacy;
