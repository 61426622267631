import React, { useState, useEffect } from "react";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { db } from "../lib/init-firebase";
// import { Table } from "flowbite-react";
import { NavLink } from "react-router-dom";
export default function PartnersDetails() {
  const partnerDetailsRef = collection(db, "partner-details");
  const q = query(partnerDetailsRef, orderBy("today", "desc"));
  const [partner, setPartner] = useState([]);

  useEffect(() => {
    getPartnerDetails();
  });

  async function getPartnerDetails() {
    const querySnapshot = await getDocs(q);

    const allDetails = querySnapshot.docs.map((doc) => ({
      data: doc.data(),
      id: doc.id,
    }));

    setPartner(allDetails);
  }

  return (
    <div>
      {/* <h4>Partner Details list</h4>
      <ul>
        {partner.map((p) => (
          <>
            <li key={p.id}> {p.data.fname}</li>
            <li key={p.id}> {p.data.lname}</li>
            <li key={p.id}> {p.data.email}</li>
            <li key={p.id}> {p.data.message}</li>
          </>
        ))}
        
      </ul> */}
      <div className="details-button p-10">
        <NavLink to="/client">
          <h4>Go Back</h4>
        </NavLink>
      </div>
      <div className="form-details">
        <h1 className="text-center text-4xl mb-[50px]">Form Details</h1>
      </div>
      <div className="form-details">
        <h1 className="text-left text-2xl mb-[20px] ml-[20px]">
          Partner Form Details
        </h1>
      </div>
      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                First Name
              </th>
              <th scope="col" className="py-3 px-6">
                Last Name
              </th>
              <th scope="col" className="py-3 px-6">
                Email
              </th>
              <th scope="col" className="py-3 px-6">
                Phone
              </th>
              <th scope="col" className="py-3 px-6">
                City
              </th>
              <th scope="col" className="py-3 px-6">
                Message
              </th>
            </tr>
          </thead>

          {partner.map((p) => (
            <tbody key={p.id}>
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <th
                  key={p.data.id}
                  scope="row"
                  className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {p.data.fname}
                </th>

                <td key={p.data.id} className="py-4 px-6">
                  {p.data.lname}
                </td>
                <td key={p.data.id} className="py-4 px-6">
                  {p.data.email}
                </td>
                <td key={p.data.id} className="py-4 px-6">
                  {p.data.phone}
                </td>
                <td key={p.data.id} className="py-4 px-6">
                  {p.data.city}
                </td>
                <td key={p.data.id} className="py-4 px-6">
                  {p.data.message}
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      </div>
    </div>
  );
}
