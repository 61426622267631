import React from "react";
import Header from "./Header";
import Hero from "./Hero";
import Quote from "./Quote";
import Services from "./Services";
import Event from "./Event";
import About from "./About";
import Partner from "./Partner";
import Locations from "./Locations";
import Contact from "./Contact";
import Footer from "./Footer";
import Rights from "./Rights";

function Elements() {
  return (
    <div>
      <Header />
      <Hero />
      <Quote />
      <Services />
      <Event />
      <About />
      <Partner />
      <Locations />
      <Contact />
      <Footer />
      <Rights />
    </div>
  );
}

export default Elements;
