import React from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../lib/init-firebase";
import { Carousel } from "flowbite-react";

function Party({ closeModal }) {
  const date = new Date().valueOf();
  const [party, setParty] = React.useState({
    fname: "",
    email: "",
    phone: "",
    date: "",
    guests: "",
    today: date,
  });
  function handleChange(event) {
    const { name, value } = event.target;
    setParty((prevParty) => ({
      ...prevParty,
      [name]: value,
    }));
  }
  const postData = async (e) => {
    e.preventDefault();
    const { fname, email, phone, date, guests, today } = party;

    const partyDetailsRef = collection(db, "parties");

    if (fname && email && phone && date && guests) {
      addDoc(partyDetailsRef, { fname, email, phone, date, guests, today })
        .then((res) => {
          console.log(res.id);
          alert(
            "Thanks for sharing the details! \nOur Team will get back to you shortly."
          );
          setParty({
            fname: "",
            email: "",
            phone: "",
            date: "",
            guests: "",
            today: 0,
          });
        })
        .catch((err) => {
          alert("Something went worng! \n Please try again after sometime.");
          console.log(err);
        });
    } else {
      alert("Please fill all the details !!");
    }
  };

  return (
    <div className="z-50 fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex items-center justify-center">
      <div className="relative bg-[#F184A6] flex flex-col tm-outer w-[350px] md:w-[750px] lg:w-[800px] h-[650px] md:h-[570px]">
        <button
          onClick={() => {
            closeModal(false);
          }}
        >
          <img
            className="absolute top-4 right-6 w-4 md:w-7"
            src="./images/sc.png"
            alt="Cancel Button"
          />
          <img
            className="absolute bottom-[28%] md:bottom-16 right-2 w-20 md:w-40 lg:w-52"
            src="./images/ep-img.png"
            alt="Event and party Background"
          />
        </button>
        <div className="mt-[40px] md:m-[20px] flex items-center flex-col md:flex-row justify-center md:justify-start  tm-upper">
          <div className=" tm-img">
            <div className="h-[185px] w-[325px] md:h-[300px] md:w-[400px]">
              <Carousel
                indicators={false}
                slide={true}
                leftControl={
                  <img
                    className="w-7 opacity-70"
                    src="./images/left-c.png"
                    alt="Left"
                  ></img>
                }
                rightControl={
                  <img
                    className="w-7 opacity-75"
                    src="./images/right-c.png"
                    alt="Right"
                  ></img>
                }
              >
                <img
                  src="./images/Event&Parties/Eventparties-1.jpg"
                  alt="Event-Parties"
                />
                <img
                  src="./images/Event&Parties/Eventparties-2.jpg"
                  alt="Event-Parties"
                />
                <img
                  src="./images/Event&Parties/Eventparties-3.jpg"
                  alt="Event-Parties"
                />
                <img
                  src="./images/Event&Parties/Eventparties-4.jpg"
                  alt="Event-Parties"
                />
                <img
                  src="./images/Event&Parties/Eventparties-5.jpg"
                  alt="Event-Parties"
                />
                <img
                  src="./images/Event&Parties/Eventparties-6.jpg"
                  alt="Event-Parties"
                />
                <img
                  src="./images/Event&Parties/Eventparties-7.jpg"
                  alt="Event-Parties"
                />
              </Carousel>
            </div>
          </div>
          <div className="mx-auto md:mx-[20px] w-[300px] md:w-[430px] tm-text">
            <h1 className="text-2xl md:text-4xl lg:text-5xl text-left leading-[3.5rem]">
              Event and Parties
            </h1>
            <p className="-mt-2 mb-2 md:mt-5 md:mb-5 text-xs lg:text-sm text-justify">
              Who let the dogs out? We did! Because your doggo and their doggo
              friends deserve a party of their own! Throw a birthday party for
              your pupper, where we plan everything from the decoration to the
              cake to return gifts. All you need to do is call their friends and
              groove to our beats!
            </p>
            <p className="text-xs lg:text-sm text-justify">
              Along with birthday parties, we also host special events and meet
              and greets for these fluffies on weekends!
            </p>
          </div>
        </div>
        <div className="mt-10 md:mt-0 m-form">
          <form
            className="w-[325px] md:w-[550px] text-xs md:text-sm lg:text-base form-div"
            action=""
          >
            <div className="form-row">
              <div className="form-col">
                <input
                  placeholder="Name"
                  className="text-xs lg:text-sm h-[35px] w-[300px] md:h-[35px] md:w-[550px]"
                  type="text"
                  name="fname"
                  value={party.fname}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-col">
                <input
                  placeholder="Email"
                  className="text-xs lg:text-sm h-[35px] md:h-[35px] w-[135px] md:w-[260px]"
                  type="email"
                  name="email"
                  value={party.email}
                  onChange={handleChange}
                />
              </div>
              <div className="form-col">
                <input
                  placeholder="Phone Number"
                  className="text-xs lg:text-sm h-[35px] md:h-[35px] w-[135px] md:w-[260px]"
                  type="number"
                  name="phone"
                  value={party.phone}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-col">
                <input
                  min={new Date().toISOString().split("T")[0]}
                  type="date"
                  name="date"
                  value={party.date}
                  onChange={handleChange}
                  placeholder="Date"
                  className="text-[10px] lg:text-sm h-[35px] md:h-[35px] w-[135px] md:w-[260px]"
                />
              </div>
              <div className="form-col">
                <input
                  placeholder="No. Of Guests"
                  className="text-xs lg:text-sm h-[35px] md:h-[35px] w-[135px] md:w-[260px]"
                  type="number"
                  name="guests"
                  value={party.guests}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="bg-[#19BCCD] form-col m-btn">
                <button
                  className="text-base lg:text-lg w-[300px] h-[30px] md:w-[550px] md:h-[40px]"
                  type="submit"
                  onClick={postData}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Party;
