import { collection, addDoc } from "firebase/firestore";
import { db } from "../lib/init-firebase";
import { Carousel } from "flowbite-react";
import React from "react";

function Swimming({ closeModal }) {
  const date = new Date().valueOf();
  const [swimming, setSwimming] = React.useState({
    fname: "",
    email: "",
    phone: "",
    date: "",
    breed: "",
    today: date,
  });

  function handleChange(event) {
    const { name, value } = event.target;
    setSwimming((prevSwimming) => ({
      ...prevSwimming,
      [name]: value,
    }));
  }

  const postData = async (e) => {
    e.preventDefault();
    const { fname, email, phone, date, breed, today } = swimming;

    const swimmingDetailsRef = collection(db, "swimming");

    if (fname && email && phone && date && breed) {
      addDoc(swimmingDetailsRef, { fname, email, phone, date, breed, today })
        .then((res) => {
          console.log(res.id);
          alert(
            "Thanks for sharing the details! \nOur Team will get back to you shortly."
          );
          setSwimming({
            fname: "",
            email: "",
            phone: "",
            date: "",
            breed: "",
            today: 0,
          });
        })
        .catch((err) => {
          alert("Something went worng! \n Please try again after sometime.");
          console.log(err);
        });
    } else {
      alert("Please fill all the details !!");
    }
  };
  return (
    <div className="z-50 fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex items-center justify-center">
      <div className="relative bg-[#FF6B63] flex flex-col tm-outer w-[350px] md:w-[750px] lg:w-[800px] h-[650px] md:h-[570px]">
        <button
          onClick={() => {
            closeModal(false);
          }}
        >
          <img
            className="absolute top-4 right-6 w-4 md:w-7"
            src="./images/bc.png"
            alt="Cancel Button"
          />
          <img
            className="absolute bottom-[27%] right-2 md:-bottom-5 md:-right-8 w-28 md:w-56 lg:w-72"
            src="./images/sm-img.png"
            alt="Swimming Background"
          />
        </button>
        <div className="mt-[40px] md:m-[20px] flex items-center flex-col md:flex-row justify-center md:justify-start  tm-upper">
          <div className=" tm-img">
            <div className=" h-[185px] w-[325px] md:h-[300px] md:w-[400px]">
              <Carousel
                indicators={false}
                slide={true}
                leftControl={
                  <img
                    className="w-7 opacity-70"
                    src="./images/left-c.png"
                    alt="Left"
                  ></img>
                }
                rightControl={
                  <img
                    className="w-7 opacity-75"
                    src="./images/right-c.png"
                    alt="Right"
                  ></img>
                }
              >
                <img src="./images/swimming/swimming-1.jpg" alt="Swimming" />
                <img src="./images/swimming/swimming-2.jpg" alt="Swimming" />
                <img src="./images/swimming/swimming-3.jpg" alt="Swimming" />
                <img src="./images/swimming/swimming-4.jpg" alt="Swimming" />
                <img src="./images/swimming/swimming-5.jpg" alt="Swimming" />
                <img src="./images/swimming/swimming-6.jpg" alt="Swimming" />
                <img src="./images/swimming/swimming-7.jpg" alt="Swimming" />
                <img src="./images/swimming/swimming-8.jpg" alt="Swimming" />
                <img src="./images/swimming/swimming-9.jpg" alt="Swimming" />
              </Carousel>
            </div>
          </div>
          <div className="mt-3 md:mt-[-100px] mx-auto md:mx-[20px] w-[300px] md:w-[430px] tm-text">
            <h1 className="text-2xl md:text-4xl lg:text-5xl text-left">
              Swimming
            </h1>
            <p className="text-xs lg:text-sm mb-2 md:mb-5 text-justify">
              Woof, woof, it’s time for some splish, splash! For all those
              tail-wagging water babies, Pupparazzi offers the largest pet pool
              in town. Your doggos can have a happy, adventurous time in our
              pool while being accompanied by our trainers at all times. It’s
              the perfect place for your pet to exercise and cool off!
            </p>
          </div>
        </div>
        <div className="mt-20 md:mt-0 m-form">
          <form
            className="w-[325px] md:w-[550px] text-xs md:text-sm lg:text-base form-div"
            action=""
          >
            <div className="form-row">
              <div className="form-col">
                <input
                  placeholder="Name"
                  className=" text-xs lg:text-sm h-[35px] w-[300px] md:h-[35px] md:w-[550px]"
                  type="text"
                  name="fname"
                  value={swimming.fname}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-col">
                <input
                  placeholder="Email"
                  className="text-xs lg:text-sm h-[35px] md:h-[35px] w-[135px] md:w-[260px]"
                  type="email"
                  name="email"
                  value={swimming.email}
                  onChange={handleChange}
                />
              </div>
              <div className="form-col">
                <input
                  placeholder="Phone Number"
                  className="text-xs lg:text-sm h-[35px] md:h-[35px] w-[135px] md:w-[260px]"
                  type="number"
                  name="phone"
                  value={swimming.phone}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-col">
                <input
                  min={new Date().toISOString().split("T")[0]}
                  type="date"
                  name="date"
                  value={swimming.date}
                  onChange={handleChange}
                  placeholder="Date"
                  className="text-[10px] lg:text-sm h-[35px] md:h-[35px] w-[135px] md:w-[260px]"
                />
              </div>
              <div className="form-col">
                <input
                  placeholder="Dog Breed"
                  className="text-xs lg:text-sm h-[35px] md:h-[35px] w-[135px] md:w-[260px]"
                  type="text"
                  name="breed"
                  value={swimming.breed}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="bg-[#F8BF53] form-col m-btn">
                <button
                  className="text-base lg:text-lg w-[300px] h-[30px] md:w-[550px] md:h-[40px]"
                  type="submit"
                  onClick={postData}
                >
                  Book Your Session
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Swimming;
