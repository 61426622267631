import React from "react";
import Training from "../modals/Training";
import Grooming from "../modals/Grooming";
import Cafe from "../modals/Cafe";
import Store from "../modals/Store";
import Swimming from "../modals/Swimming";
import Party from "../modals/Party";
import Boarding from "../modals/Boarding";

const Services = () => {
  const [trainingModal, setTrainingModal] = React.useState(false);
  const [groomingModal, setGroomingModal] = React.useState(false);
  const [cafeModal, setCafeModal] = React.useState(false);
  const [storeModal, setStoreModal] = React.useState(false);
  const [swimmingModal, setSwimmingModal] = React.useState(false);
  const [partyModal, setPartyModal] = React.useState(false);
  const [boardingModal, setBoardingModal] = React.useState(false);

  return (
    <div className="wrapper ">
      <div className="upperwaves mt-[-70px] md:mt-[-100px] lg:mt-[-100px] xl:mt-[-160px]">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#ffdfa7"
            // fill-opacity="1"
            d="M0,128L48,138.7C96,149,192,171,288,154.7C384,139,480,85,576,58.7C672,32,768,32,864,53.3C960,75,1056,117,1152,133.3C1248,149,1344,139,1392,133.3L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
        </svg>
      </div>
      <div id="services" className="services relative  ">
        <div className="service-elements h-full md:h-screen lg:h-screen">
          {/* <div className="opacity-100 md:opacity-0 absolute top-16 ">
            <img
              className=" mt-[20px] w-full ser-bg"
              src="./images/service-bg1.svg"
              alt=""
            />
          </div>
          <div className="opacity-100 md:opacity-0 absolute top-[500px]">
            <img
              className="w-full ser-bg"
              src="./images/service-bg2.svg"
              alt=""
            />
          </div>
          <div className="opacity-100 md:opacity-0 absolute top-[1000px]">
            <img
              className="w-full ser-bg"
              src="./images/service-bg3.svg"
              alt=""
            />
          </div> */}
          <div className="service-content ">
            <h1 className="text-5xl md:text-6xl lg:text-8xl mt-[-20px] md:mt-[-90px] lg:mt-[-150px]">
              Services
            </h1>
            <img
              className="hidden md:block md:mt-[100px] lg:mt-[-40px] absolute w-full ser-bg"
              src="./images/ser-bg.png"
              alt="Background"
            />
          </div>
          <div className="training flex flex-col justify-center items-center md:absolute lg:absolute md:top-[17%] md:left-[5%] mt-5">
            <button
              onClick={() => {
                setTrainingModal(true);
              }}
            >
              <img
                className="w-36 md:w-48 lg:w-64"
                src="./images/training.png"
                alt="Training"
              />
              <h4 className="text-2xl md:text-3xl lg:text-4xl">Training</h4>
            </button>
            {trainingModal && <Training closeModal={setTrainingModal} />}
          </div>
          <div className="grooming flex flex-col justify-center  items-center md:absolute lg:absolute top-[7%] left-[40%] mt-5">
            <button
              onClick={() => {
                setGroomingModal(true);
              }}
            >
              <img
                className="w-36 md:w-48 lg:w-64"
                src="./images/grooming.png"
                alt="Grooming"
              />
              <h4 className="text-2xl md:text-3xl lg:text-4xl">Grooming</h4>
            </button>
            {groomingModal && <Grooming closeModal={setGroomingModal} />}
          </div>
          <div className="cafe mt-5 flex flex-col justify-center  items-center md:absolute lg:absolute top-[20%] right-[5%]">
            <button
              onClick={() => {
                setCafeModal(true);
              }}
            >
              <img
                className="w-40 md:w-64 lg:w-80"
                src="./images/cafe.png"
                alt="Cafe"
              />
              <h4 className="text-2xl md:text-3xl lg:text-4xl">
                Pet Friendly Cafe
              </h4>
            </button>
            {cafeModal && <Cafe closeModal={setCafeModal} />}
          </div>
          <div className="pet-store flex flex-col justify-center  items-center md:absolute lg:absolute top-[40%] left-[38%] mt-5">
            <button
              onClick={() => {
                setStoreModal(true);
              }}
            >
              <img
                className="w-48 md:w-56 lg:w-72"
                src="./images/pet-store.png"
                alt="Pet Store"
              />
              <h4 className="text-2xl md:text-3xl lg:text-4xl">Pet Store</h4>
            </button>
            {storeModal && <Store closeModal={setStoreModal} />}
          </div>
          <div className="swimming mt-5 flex flex-col justify-center  items-center  md:absolute lg:absolute top-[58%] left-[5%]">
            <button
              onClick={() => {
                setSwimmingModal(true);
              }}
            >
              <img
                className="w-48 md:w-64 lg:w-80"
                src="./images/swimming.png"
                alt="Swimming"
              />
              <h4 className="text-2xl md:text-3xl lg:text-4xl">Swimming</h4>
            </button>
            {swimmingModal && <Swimming closeModal={setSwimmingModal} />}
          </div>
          <div className="boarding mt-5 flex flex-col justify-center  items-center md:absolute lg:absolute top-[67%] left-[38%]">
            <button
              onClick={() => {
                setBoardingModal(true);
              }}
            >
              <img
                className="w-40 md:w-56 lg:w-64"
                src="./images/boarding.png"
                alt="Boarding"
              />
              <h4 className="text-2xl md:text-3xl lg:text-4xl">Boarding</h4>
            </button>
            {boardingModal && <Boarding closeModal={setBoardingModal} />}
          </div>
          <div className="party mt-5 flex flex-col justify-center  items-center md:absolute lg:absolute top-[50%] right-[5%]">
            <button
              onClick={() => {
                setPartyModal(true);
              }}
            >
              <img
                className="w-48 md:w-64 lg:w-80"
                src="./images/parties.png"
                alt="Event and Parties"
              />
              <h4 className="text-2xl md:text-3xl lg:text-4xl">
                Event and Parties
              </h4>
            </button>
            {partyModal && <Party closeModal={setPartyModal} />}
          </div>
        </div>
        <div className="lowerwaves">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="#ffdfa7"
              // fill-opacity="1"
              d="M0,128L60,122.7C120,117,240,107,360,128C480,149,600,203,720,197.3C840,192,960,128,1080,128C1200,128,1320,192,1380,224L1440,256L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Services;
