import React from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../lib/init-firebase";
import { Carousel } from "flowbite-react";

function Grooming({ closeModal }) {
  const date = new Date().valueOf();
  // const today = ;
  // console.log(date);

  const [grooming, setGrooming] = React.useState({
    fname: "",
    email: "",
    phone: "",
    date: "",
    breed: "",
    today: date,
  });

  function handleChange(event) {
    const { name, value } = event.target;
    setGrooming((prevGrooming) => ({
      ...prevGrooming,
      [name]: value,
    }));
  }

  const postData = async (e) => {
    e.preventDefault();
    const { fname, email, phone, date, breed, today } = grooming;

    const groomingDetailsRef = collection(db, "grooming");

    if (fname && email && phone && date && breed) {
      addDoc(groomingDetailsRef, { fname, email, phone, date, breed, today })
        .then((res) => {
          alert(
            "Thanks for sharing the details! \nOur Team will get back to you shortly."
          );
          setGrooming({
            fname: "",
            email: "",
            phone: "",
            date: "",
            breed: "",
            today: 0,
          });
        })
        .catch((err) => {
          alert("Something went worng! \n Please try again after sometime.");
          console.log(err);
        });
    } else {
      alert("Please fill all the details !!");
    }
  };

  return (
    <div className="z-50 fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex items-center justify-center">
      <div className="relative bg-[#F8BF53] flex flex-col tm-outer w-[350px] md:w-[750px] lg:w-[800px] h-[650px] md:h-[570px]">
        <button
          onClick={() => {
            closeModal(false);
          }}
        >
          <img
            className="absolute top-4 right-6 w-4 md:w-7"
            src="./images/gc.png"
            alt="Cancel Button"
          />
          <img
            className="absolute bottom-44 right-2 md:bottom-2 md:-right-5 w-24 lg:w-64"
            src="./images/gm-img.png"
            alt="Cafe Background"
          />
        </button>
        <div className="mt-[40px] md:m-[20px] flex items-center flex-col md:flex-row justify-center md:justify-start  tm-upper">
          <div className=" tm-img">
            <div className="h-[185px] w-[325px] md:h-[300px] md:w-[400px]">
              <Carousel
                indicators={false}
                slide={true}
                leftControl={
                  <img
                    className="w-7 opacity-70"
                    src="./images/left-c.png"
                    alt="Left"
                  ></img>
                }
                rightControl={
                  <img
                    className="w-7 opacity-75"
                    src="./images/right-c.png"
                    alt="Right"
                  ></img>
                }
              >
                <img src="./images/Grooming/grooming-1.jpg" alt="Grooming" />
                <img src="./images/Grooming/grooming-2.jpg" alt="Grooming" />
                <img src="./images/Grooming/grooming-3.jpg" alt="Grooming" />
                <img src="./images/Grooming/grooming-4.jpg" alt="Grooming" />
                <img src="./images/Grooming/grooming-5.jpg" alt="Grooming" />
                <img src="./images/Grooming/grooming-6.jpg" alt="Grooming" />
                <img src="./images/Grooming/grooming-7.jpg" alt="Grooming" />
                <img src="./images/Grooming/grooming-8.jpg" alt="Grooming" />
              </Carousel>
            </div>
          </div>
          <div className="mt-2 md:mt-[-50px] mx-auto md:mx-[20px] w-[300px] md:w-[430px] tm-text">
            <h1 className="text-2xl md:text-4xl lg:text-5xl text-left">
              Grooming
            </h1>
            <p className=" text-xs lg:text-sm mb-2 md:mb-5 text-justify">
              Make way for the most dashing looking doggos in town, cuz
              Pupparazzi’s grooming services are just pawsome! Our highly
              experienced and well trained staff will make sure that your pet is
              well groomed and healthy, while also tracking their well-being
              with our grooming card. And to top that, our transparent door
              policy will make sure that you as well as your fluffies have a
              stress-free and rejuvenating experience!
            </p>
          </div>
        </div>
        <div className="mt-12 md:mt-0 m-form">
          <form
            className="w-[325px] md:w-[550px] text-xs md:text-sm lg:text-base form-div"
            action=""
          >
            <div className="form-row">
              <div className="form-col">
                <input
                  placeholder="Name"
                  className="text-xs lg:text-sm h-[35px] w-[300px] md:h-[35px] md:w-[550px]"
                  type="text"
                  name="fname"
                  value={grooming.fname}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-col">
                <input
                  placeholder="Email"
                  className="text-xs lg:text-sm h-[35px] md:h-[35px] w-[135px] md:w-[260px]"
                  type="email"
                  name="email"
                  value={grooming.email}
                  onChange={handleChange}
                />
              </div>
              <div className="form-col">
                <input
                  placeholder="Phone Number"
                  className="text-xs lg:text-sm h-[35px] md:h-[35px] w-[135px] md:w-[260px]"
                  type="number"
                  name="phone"
                  value={grooming.phone}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-col">
                <input
                  min={new Date().toISOString().split("T")[0]}
                  type="date"
                  name="date"
                  value={grooming.date}
                  onChange={handleChange}
                  placeholder="Date"
                  className="text-[10px] lg:text-sm h-[35px] md:h-[35px] w-[135px] md:w-[260px]"
                />
              </div>
              <div className="form-col">
                <input
                  placeholder="Dog Breed"
                  className="text-xs lg:text-sm h-[35px] md:h-[35px] w-[135px] md:w-[260px]"
                  type="text"
                  name="breed"
                  value={grooming.breed}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="bg-[#FF6B63] form-col m-btn">
                <button
                  className="text-base lg:text-lg w-[300px] h-[30px] md:w-[550px] md:h-[40px]"
                  type="submit"
                  onClick={postData}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Grooming;
