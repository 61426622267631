import React from "react";
import { Carousel } from "flowbite-react";
function Cafe({ closeModal }) {
  return (
    <div className="z-50 fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex items-center justify-center">
      <div className="relative bg-[#F8BF53] flex flex-col tm-outer w-[350px] md:w-[750px] lg:w-[800px] h-[610px] md:h-[570px]">
        <button
          onClick={() => {
            closeModal(false);
          }}
        >
          <img
            className="absolute top-4 right-6 w-4 md:w-7"
            src="./images/gc.png"
            alt="Cancel Button"
          />
          <img
            className="absolute bottom-2 left-16 w-32 md:w-64"
            src="./images/cm-img.png"
            alt="Cafe Background"
          />
        </button>
        <div className="mt-[40px] md:m-[20px] flex items-center flex-col md:flex-row justify-center md:justify-start  tm-upper">
          <div className=" tm-img">
            <div className="h-[185px] w-[325px] md:h-[300px] md:w-[400px]">
              <Carousel
                indicators={false}
                slide={true}
                leftControl={
                  <img
                    className="w-7 opacity-70"
                    src="./images/left-c.png"
                    alt="Left"
                  ></img>
                }
                rightControl={
                  <img
                    className="w-7 opacity-75"
                    src="./images/right-c.png"
                    alt="Right"
                  ></img>
                }
              >
                <img src="./images/Cafe/cafe-1.jpg" alt="Cafe Carousels" />
                <img src="./images/Cafe/cafe-2.jpg" alt="Cafe Carousels" />
                <img src="./images/Cafe/cafe-3.jpg" alt="Cafe Carousels" />
                <img src="./images/Cafe/cafe-4.jpg" alt="Cafe Carousels" />
                <img src="./images/Cafe/cafe-5.jpg" alt="Cafe Carousels" />
                <img src="./images/Cafe/cafe-6.jpg" alt="Cafe Carousels" />
                <img src="./images/Cafe/cafe-7.jpg" alt="Cafe Carousels" />
                <img src="./images/Cafe/cafe-8.jpg" alt="Cafe Carousels" />
                <img src="./images/Cafe/cafe-9.jpg" alt="Cafe Carousels" />
                <img src="./images/Cafe/cafe-10.jpg" alt="Cafe Carousels" />
                <img src="./images/Cafe/cafe-11.jpg" alt="Cafe Carousels" />
                <img src="./images/Cafe/cafe-12.jpg" alt="Cafe Carousels" />
              </Carousel>
            </div>
          </div>
          <div className="mt-2 md:mt-0 mx-auto md:mx-[20px] w-[300px] md:w-[430px] tm-text">
            <h1 className="text-2xl md:text-4xl lg:text-5xl text-left leading-[3.5rem] text-left">
              Pet Friendly Cafe
            </h1>
            <p className="text-xs lg:text-sm mb-5 text-justify">
              Did someone say treats? Treat yourself and your pet to some
              delicious food at our pet-friendly cafe. Choose drool-worthy
              delicacies from our two menus - one for you and one for them. A
              great indoor and outdoor ambience, yummy food and your cute little
              doggo! What more could you ask for!
            </p>
          </div>
        </div>
        <div className="absolute bottom-32 right-8 md:bottom-48 md:right-10 w-[250px] lg:w-[300px] text-center bg-[#FF6B63] form-col m-btn">
          <a
            href="https://maps.google.com/?cid=11204830985246932760&entry=gps"
            target="_blank"
            rel="noreferrer"
          >
            <button className="text-base lg:text-lg" type="submit">
              Visit Now
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Cafe;
