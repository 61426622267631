import React from "react";
import { FaBars } from "react-icons/fa";
import { NavLink } from "react-router-dom";

export default function Header({ fixed }) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  return (
    <div id="header">
      <div className="flex flex-wrap py-2 navbar">
        <div className="w-full px-4 ">
          <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 rounded">
            <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
              <div className="w-full relative flex justify-between lg:w-auto px-4 lg:static lg:block lg:justify-start">
                {/* <a
                  className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-white"
                  href="#hero"
                >
                  <img src="./images/logo.png" alt="" />
                </a> */}
                <NavLink
                  to="/"
                  className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-white"
                >
                  <img className="w-[80%]" src="./images/logo.svg" alt="Logo" />
                </NavLink>
                <button
                  className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                  type="button"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  <FaBars className="nb-fabars" />
                </button>
              </div>
              <div
                className={
                  "lg:flex flex-grow items-center" +
                  (menuOpen ? " flex" : " hidden")
                }
                id="example-navbar-info"
              >
                <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                  <li className="nav-item">
                    <a
                      className="px-3 py-2 flex items-center text-md uppercase font-bold leading-snug text-black
                      hover:text-[#ff6b63]"
                      href="#services"
                    >
                      Services
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="px-3 py-2 flex items-center text-md uppercase font-bold leading-snug text-black hover:text-[#ff6b63]"
                      href="#event"
                    >
                      Gallery
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="px-3 py-2 flex items-center text-md uppercase font-bold leading-snug text-black hover:text-[#ff6b63]"
                      href="#about"
                    >
                      About Us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="px-3 py-2 flex items-center text-md uppercase font-bold leading-snug text-black hover:text-[#ff6b63]"
                      href="#partner"
                    >
                      Franchise
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="px-3 py-2 flex items-center text-md uppercase font-bold leading-snug text-black hover:text-[#ff6b63]"
                      href="#location"
                    >
                      Locations
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="px-3 py-2 flex items-center text-md uppercase font-bold leading-snug text-black hover:text-[#ff6b63]"
                      href="#contact"
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}
