import React from "react";

const About = () => {
  return (
    <div className="-mt-20 md:mt-40" id="about">
      <section className="about">
        <div className="about-content h-full flex flex-col justify-between items-center lg:flex-row md:flex-row md:items-center lg:items-center md:justify-between lg:justify-around">
          <div className="about-img ">
            <div className="img-bg flex justify-center items-center w-[250px] h-[250px] md:w-[300px] lg:w-[500px] md:h-[300px] lg:h-[500px]">
              <img
                className="w-[225px] h-[225px] md:w-[270px] lg:w-[450px] md:h-[270px] lg:h-[450px]"
                src="./images/about-us-img.png"
                alt="About-Us"
              />
            </div>
          </div>
          <div className="px-3 about-text mt-10 mb-16">
            <h1 className="text-5xl md:text-6xl lg:text-8xl">About Us</h1>
            <p className="mb-5 text-sm md:text-l lg:text-xl">
              The journey of being a pet pawrent can only be understood and
              embraced by another pet pawrent. Pupparazzi is the pet-child of
              Naiya Modi, a loving pet parent of Simba. A few years ago, Naiya
              struggled to find one comprehensive space that catered to all her
              pet’s needs. That’s when she, alongside Maulik Patel embarked on
              this beautiful journey to create ‘Every Dog’s Wonderland’.
            </p>
            <p className="mb-5 text-sm md:text-l lg:text-xl">
              Being pet lovers, we understand that your pet is your little baby,
              your family. At Pupparazzi, our aim is to shower your pet with
              utmost affection and care, and ensure that you and your pet go
              back with a wholesome and fulfilling experience.
            </p>
            <p className="mb-5 text-sm md:text-l lg:text-xl">
              Our journey is filled with learning, licks and love. Having built
              the biggest pet community in Ahmedabad, we hope to grow nationwide
              and make their world healthier and happier. And to say the least,
              we believe it’s their world, we’re just living in it!
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
