import React from "react";
import Maps from "./Maps";
function Locations() {
  return (
    <div>
      <div className=" wrapper">
        <div className="upperwaves mt-[-70px] md:mt-[-70px] lg:mt-[-100px] xl:mt-[-100px]">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="#FFDA95"
              // fill-opacity="1"
              d="M0,128L48,138.7C96,149,192,171,288,154.7C384,139,480,85,576,58.7C672,32,768,32,864,53.3C960,75,1056,117,1152,133.3C1248,149,1344,139,1392,133.3L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
            ></path>
          </svg>
        </div>
        <div id="location" className="h-full location relative">
          <div className=" absolute top-[7%] md:top-[5%] md:left-5">
            <img
              className="w-[250px] md:w-[350px] lg:w-[500px]"
              src="./images/location-bg1.png"
              alt="Background"
            />
          </div>
          <div className="z-0 absolute top-[25%] lg:-top-10 right-10 lg:right-0">
            <img
              className="w-[250px] md:w-[350px] lg:w-[500px]"
              src="./images/location-bg2.png"
              alt="Background"
            />
          </div>
          <div className="z-0 absolute left-[25%] md:left-[30%] top-[50%] lg:left-[40%] lg:top-[40%]">
            <img
              className="w-[450px] md:w-[550px] lg:w-[700px]"
              src="./images/location-bg3.png"
              alt="Background"
            />
          </div>
          <div className="location-elements">
            <div className="z-10 mb-[20px] lg:mb-[50px] location-header">
              <h1 className="text-center text-5xl md:text-6xl lg:text-8xl mt-[-20px] md:mt-[-90px] lg:mt-[-130px]">
                Find Us
              </h1>
            </div>
            <div
              className="px-12 md:px-16 l:px-20 xl:px-36 flex 
          flex-col-reverse lg:flex-row items-center justify-center lg:justify-between"
            >
              <div className="z-20 mx-5 location-left">
                <div className="w-[300px] h-[190px] md:w-[500px] md:h-[250px] location1">
                  <h4 className="text-base md:text-2xl">Pupparrazi Club</h4>
                  <h4 className="text-base md:text-2xl">South Bopal</h4>
                  <p className="text-sm md:text-xl">
                    Opp. Stanza, Shela Road, South Bopal, Ahmedabad-380058
                  </p>
                  <a href="https://maps.google.com/?cid=11204830985246932760&entry=gps">
                    <button className="mt-3 w-[120px] h-[30px] md:w-[165px] md:h-[45px] btn-press">
                      Locate Us
                    </button>
                  </a>
                </div>
                <div className="w-[300px] h-[190px] md:w-[500px] md:h-[250px] location2">
                  <h4 className="text-base md:text-2xl">
                    Pet Store & Grooming Salon
                  </h4>{" "}
                  <h4 className="text-base md:text-2xl">Sindhu Bhavan Road</h4>
                  <p className="text-sm md:text-xl">
                    112, First Floor, Shreeya Atlanta, Nr. Times Square, Opp.
                    Sindhubhavan Road, Ahmedabad-380054
                  </p>
                  <a href="https://maps.app.goo.gl/pvAGkPQpGref6fdC6">
                    <button className="mt-3 w-[120px] h-[30px] md:w-[165px] md:h-[45px] btn-press">
                      Locate Us
                    </button>
                  </a>
                </div>
                <div className="w-[300px] h-[190px] md:w-[500px] md:h-[250px] location3">
                  <h4 className="text-base md:text-2xl">
                    Pet Store & Grooming Salon
                  </h4>{" "}
                  <h4 className="text-base md:text-2xl">Vaishnodevi</h4>
                  <p className="text-sm md:text-xl">
                    11 & 12, Shaligram Lakeview, S.P. Ring Road, Nr. Vaishnodevi
                    Circle, Ahmedabad-382421
                  </p>
                  <a href="https://maps.app.goo.gl/DSW1WKGk6j3KSoNA8">
                    <button className="mt-3 w-[120px] h-[30px] md:w-[165px] md:h-[45px] btn-press">
                      Locate Us
                    </button>
                  </a>
                </div>
                <div className="w-[300px] h-[190px] md:w-[500px] md:h-[250px] location4">
                  <h4 className="text-base md:text-2xl">
                    Pet Store & Grooming Salon
                  </h4>
                  <h4 className="text-base md:text-2xl">Jamnagar </h4>
                  <p className="text-sm md:text-xl">
                    10 & 11, Road No. 1, Opp. Sarvoday Engineering, Patel
                    Colony, Jamnagar-361008
                  </p>
                  <a href="https://maps.app.goo.gl/hg3DB3UGP5Te4voK8">
                    <button className="mt-3 w-[120px] h-[30px] md:w-[165px] md:h-[45px] btn-press">
                      Locate Us
                    </button>
                  </a>
                </div>
              </div>
              <div className="z-10 mx-5 mb-16 w-[300px] h-[150px] md:w-[500px] md:h-[200px] lg:w-[500px] lg:h-[950px] xl:w-[500px] xl:h-[950px] location-right">
                <Maps />
              </div>
            </div>
          </div>
          <div className="lowerwaves">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
              <path
                fill="#FFDA95"
                // fill-opacity="1"
                d="M0,224L60,202.7C120,181,240,139,360,133.3C480,128,600,160,720,192C840,224,960,256,1080,234.7C1200,213,1320,139,1380,101.3L1440,64L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      {/* <Maps /> */}
    </div>
  );
}

export default Locations;
