import { collection, addDoc } from "firebase/firestore";
import { db } from "../lib/init-firebase";

import React from "react";

function Contact() {
  const date = new Date().valueOf();
  const [contact, setContact] = React.useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    message: "",
    today: date,
  });

  function handleChange(event) {
    const { name, value } = event.target;
    setContact((prevContact) => ({
      ...prevContact,
      [name]: value,
    }));
  }

  const postData = async (e) => {
    e.preventDefault();
    const { fname, lname, email, phone, message, today } = contact;

    const contactDetailsRef = collection(db, "contact-us");

    if (fname && lname && email && phone && message) {
      addDoc(contactDetailsRef, { fname, lname, email, phone, message, today })
        .then((res) => {
          console.log(res.id);
          alert(
            "Thanks for sharing the details! \nOur Team will get back to you shortly."
          );
          setContact({
            fname: "",
            lname: "",
            email: "",
            phone: "",
            message: "",
            today: 0,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert("Please fill all the details !!");
    }
  };

  return (
    <div id="contact">
      <section className="mb-[170px] contact-us">
        <h1 className="mb-[50px] text-center mt-[150px] lg:mt-[100px] lg:ml-[40px] lg:text-left text-4xl md:text-6xl lg:text-8xl">
          Contact Us
        </h1>
        <div className="relative ">
          <img
            className="absolute right-0 mt-[-20px] lg:mt-[100px] w-[350px] md:w-[450px] lg:w-[550px]"
            src="./images/contactbg.png"
            alt="Background"
          />
        </div>
        <div className="mx-10 flex flex-col-reverse lg:flex-row items-center justify-around contact-content">
          <div className=" contact-form">
            <div className="h-[550px] w-[350px] md:h-[550px] md:w-[550px] lg:h-[600px] lg:w-[600px] form-outter">
              <form
                className="mt-[20px] text-xs md:text-sm lg:text-base form-div"
                action=""
              >
                <div className="form-row">
                  <div className="form-col">
                    <label>First Name</label>
                    <input
                      className="w-[100px] md:w-[150px] lg:w-[200px]"
                      type="text"
                      name="fname"
                      value={contact.fname}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-col">
                    <label>Last Name</label>
                    <input
                      className="w-[100px] md:w-[150px] lg:w-[200px]"
                      type="text"
                      name="lname"
                      value={contact.lname}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-col">
                    <label>Email</label>
                    <input
                      className="w-[100px] md:w-[150px] lg:w-[200px]"
                      type="email"
                      name="email"
                      value={contact.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-col">
                    <label>Phone Number</label>
                    <input
                      className="w-[100px] md:w-[150px] lg:w-[200px]"
                      type="number"
                      name="phone"
                      value={contact.phone}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-col">
                    <label>Message</label>
                    <textarea
                      cols="50"
                      rows="8"
                      name="message"
                      value={contact.message}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
                <div className="mt-[50px] form-btn">
                  <button type="submit" onClick={postData}>
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="w-[350px] md:w-[700px] lg:w-[800px] contact-img">
            <img src="./images/cfcall.png" alt="Dog-Call" />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;
