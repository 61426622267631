import React from "react";

const Hero = () => {
  return (
    <div id="hero">
      <section className="hero  absolute">
        <img src="./images/hero.png" alt="Hero" />
        {/* <div className="video-container relative top-0 left-0 w-full">
          <video autoplay="autoplay" loop muted>
            <source src="./images/video.mp4" />
          </video>
        </div> */}
        <h1
          className="
        text-xl font-bold  md:text-4xl lg:text-6xl lg:max-w-7xl
        absolute top-[30%] lg:top-[60%] 
        "
        >
          Your Furry Friend’s Wonderland!
        </h1>
        <p
          className="leading-nomal mt-5 text-l md:text-2xl lg:text-4xl lg:max-w-5xl text-center
        absolute top-[40%] lg:top-[70%]"
        >
          A place that makes every dog's tail wag with joy!
        </p>
      </section>
    </div>
  );
};

export default Hero;
