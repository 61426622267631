import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIsAuth } from "../redux/AuthSlice";

function Admin() {
  const credentials = {
    credId: process.env.REACT_APP_LOGIN_ID,
    credPassword: process.env.REACT_APP_PASSWORD,
  };

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  function handleEmailChange(event) {
    setEmail(event.target.value);
  }
  function handlePasswordChange(event) {
    setPassword(event.target.value);
  }
  function login() {
    if (email === credentials.credId && password === credentials.credPassword) {
      console.log("Loggen In successfully");
      dispatch(setIsAuth(true));
      navigate("/client");
    } else {
      alert("Login Failed... Email or Password Incorrect");
    }
  }
  return (
    <div className="w-[600px] mt-[100px] mx-auto">
      <div className="p-[50px] border-solid border-2 border-black flex flex-col items-center justify-center">
        <h1 className="text-3xl mb-[20px]">Pupparazzi Admin Login Panel</h1>
        {/* <h2>Login ID</h2> */}
        <input
          className="mb-5"
          type="text"
          placeholder="Login ID"
          onChange={handleEmailChange}
        />
        {/* <h2>Password</h2> */}
        <input
          type="password"
          name="password"
          placeholder="password"
          onChange={handlePasswordChange}
        />
        <button onClick={login} className="block details-button">
          Log In
        </button>
      </div>
    </div>
  );
}

export default Admin;
