import React from "react";
import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <div>
      <footer className="hidden md:block flex items-center justify-between">
        <div className="flex items-center justify-evenly h-full p-5 footer-content">
          <div className="footer-logo">
            <NavLink to="/">
              <img src="./images/footer-logo.png" alt="Logo" />
            </NavLink>
          </div>
          <div className="footer-row">
            <div className="footer-col address">
              <img src="./images/pin.png" alt="Pin" />
              <a
                href="https://maps.google.com/?cid=11204830985246932760&entry=gps"
                target="_blank"
                rel="noreferrer"
              >
                <h3 className="w-[100px] md:w-[170px] lg:w-[300px] text-xs md:text-base lg:text-lg">
                  Next Crossroad to Bharat Petroleum, VIP Rd, opp. Stanza, South
                  Bopal, Ahmedabad, Gujarat 380058
                </h3>
              </a>
            </div>
            <div className="footer-col phone">
              <img src="./images/phone.png" alt="Phone" />
              <a href="tel:6358848177">
                <h3 className="w-[100px] md:w-[170px] lg:w-[300px] text-xs md:text-base lg:text-lg">
                  63588 48177
                </h3>
              </a>
            </div>
            <div className="footer-col mail">
              <img src="./images/mail.png" alt="Mail" />
              <a href="mailto:info@pupparazziclub.com">
                <h3 className="w-[100px] md:w-[170px] lg:w-[300px] text-xs md:text-base lg:text-lg">
                  info@pupparazziclub.com
                </h3>
              </a>
            </div>
          </div>
          <div className="footer-row">
            <h2 className="text-base md:text-lg lg:text-xl">KNOW MORE</h2>
            <a href="#services">
              <h3 className="text-xs md:text-base lg:text-lg">Services</h3>
            </a>
            <a href="#event">
              <h3 className="text-xs md:text-base lg:text-lg">Events</h3>
            </a>
            <a href="#about">
              <h3 className="text-xs md:text-base lg:text-lg">About Us</h3>
            </a>
            <a href="#partner">
              <h3 className="text-xs md:text-base lg:text-lg">Franchise</h3>
            </a>
            <a href="#contact">
              <h3 className="text-xs md:text-base lg:text-lg">Contact Us</h3>
            </a>
          </div>
          <div className="footer-row ">
            <div className="mt-[-5px] mb-10">
              <h2 className="text-base md:text-lg lg:text-xl">INFORMATION</h2>
              <div className="flex flex-col footer-info">
                <div>
                  <NavLink to="/privacy">
                    <h3 className="text-xs md:text-base lg:text-lg">
                      Privacy Policy
                    </h3>
                  </NavLink>
                </div>
                <div>
                  <NavLink to="/terms">
                    <h3 className=" text-xs md:text-base lg:text-lg">
                      Terms of Use
                    </h3>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="footer-row">
              <h2 className="text-base md:text-lg lg:text-xl">FOLLOW</h2>
              <div className="footer-col">
                <a
                  href="https://instagram.com/pupparazziclub?igshid=ZmVmZTY5ZGE="
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="w-[50%] md:w-[70%] lg:w-[100%]"
                    src="./images/insta.png"
                    alt="Instagram"
                  />
                </a>
                <a
                  href="https://www.facebook.com/pupparazziclub/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="w-[50%] md:w-[70%] lg:w-[100%]"
                    src="./images/fb.png"
                    alt="Facebook"
                  />
                </a>
                <a
                  href="https://youtube.com/@pupparazziclubofficial5072"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="w-[50%] md:w-[70%] lg:w-[100%]"
                    src="./images/yt.png"
                    alt="YouTube"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <footer className=" block md:hidden flex items-center justify-between">
        <div className="p-7 flex flex-col lg:flex-row  items-start lg:items-center justify-evenly h-full  footer-content1">
          <div className="mb-3 footer-logo">
            <NavLink to="/">
              <img
                className="w-[100%]"
                src="./images/footer-logo2.png"
                alt="Logo"
              />
            </NavLink>
          </div>
          <div className="footer-row">
            <div className="footer-col address">
              <img src="./images/pin.png" alt="Address Pin" />
              <a
                href="https://maps.google.com/?cid=11204830985246932760&entry=gps"
                target="_blank"
                rel="noreferrer"
              >
                <h3 className="w-[200px] md:w-[300px] text-xs md:text-base lg:text-lg">
                  Next Crossroad to Bharat Petroleum, VIP Rd, opp. Stanza, South
                  Bopal, Ahmedabad, Gujarat 380058
                </h3>
              </a>
            </div>
            <div className="footer-col phone">
              <img src="./images/phone.png" alt="Phone" />
              <a href="tel:6358848177">
                <h3 className="w-[200px] md:w-[300px] text-xs md:text-base lg:text-lg">
                  63588 48177
                </h3>
              </a>
            </div>
            <div className="footer-col mail">
              <img src="./images/mail.png" alt="Mail" />
              <a href="mailto:info@pupparazziclub.com">
                <h3 className="w-[200px] md:w-[300px] text-xs md:text-base lg:text-lg">
                  info@pupparazziclub.com
                </h3>
              </a>
            </div>
          </div>
          <div className="w-full footer-row">
            <div className="flex flex-col ">
              <div>
                <h2 className=" text-base md:text-lg lg:text-xl ">KNOW MORE</h2>
              </div>
              <div className="flex flex-row justify-between">
                <div>
                  <a href="#services">
                    <h3 className=" text-xs md:text-base lg:text-lg">
                      Services
                    </h3>
                  </a>
                </div>
                <div>
                  <a href="#event">
                    <h3 className="text-xs md:text-base lg:text-lg">Events</h3>
                  </a>
                </div>
                <div>
                  <a href="#about">
                    <h3 className="text-xs md:text-base lg:text-lg">About</h3>
                  </a>
                </div>
                <div>
                  <a href="#partner">
                    <h3 className="text-xs md:text-base lg:text-lg">
                      Franchise
                    </h3>
                  </a>
                </div>
                <div>
                  <a href="#location">
                    <h3 className="text-xs md:text-base lg:text-lg">
                      Location
                    </h3>
                  </a>
                </div>
                <div>
                  <a href="#contact">
                    <h3 className="text-xs md:text-base lg:text-lg">Contact</h3>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[70%] footer-row ">
            <div className="flex flex-col">
              <div>
                <h2 className="text-base md:text-lg lg:text-xl">INFORMATION</h2>
              </div>
              <div className="flex flex-row footer-col footer-info">
                <div>
                  <NavLink to="/privacy">
                    <h3 className="text-xs md:text-base lg:text-lg">
                      Privacy Policy
                    </h3>
                  </NavLink>
                </div>
                <div>
                  <NavLink to="/terms">
                    <h3 className="text-xs md:text-base lg:text-lg">
                      Terms of Use
                    </h3>
                  </NavLink>
                </div>
              </div>
              <div className="w-[70%] footer-row">
                <h2 className="text-base md:text-lg lg:text-xl">FOLLOW</h2>
                <div className="footer-col">
                  <a
                    href="https://instagram.com/pupparazziclub?igshid=ZmVmZTY5ZGE="
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="w-[50%] md:w-[70%] lg:w-[100%]"
                      src="./images/insta.png"
                      alt="Instagram"
                    />
                  </a>
                  <a
                    href="https://www.facebook.com/pupparazziclub/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="w-[50%] md:w-[70%] lg:w-[100%]"
                      src="./images/fb.png"
                      alt="Facebook"
                    />
                  </a>
                  <a
                    href="https://youtube.com/@pupparazziclubofficial5072"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="w-[50%] md:w-[70%] lg:w-[100%]"
                      src="./images/yt.png"
                      alt="Youtube"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
