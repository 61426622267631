import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Elements from "./components/Elements";
import BoardingDetails from "./components/BoardingDetails";
import PartnersDetails from "./components/PartnersDetails";
import GroomingDetails from "./components/GroomingDetails";
import PartytDetails from "./components/PartnersDetails";
import SwimmingDetails from "./components/SwimmingDetails";
import TrainingDetails from "./components/TrainingDetails";
import Client from "./components/Client";
import Admin from "./components/Admin";
import ContactDetails from "./components/ContactDetails";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";
import "./App.css";
import { useSelector } from "react-redux";

function App() {
  const isAuth = useSelector((state) => state.auth.isAuth);
  // console.log(isAuth);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Elements />} />
        <Route path="/admin" element={<Admin />} />

        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        {isAuth && <Route path="/client" element={<Client />} />}
        {isAuth && <Route path="/contact" element={<ContactDetails />} />}
        {isAuth && <Route path="/partner" element={<PartnersDetails />} />}
        {isAuth && <Route path="/boarding" element={<BoardingDetails />} />}
        {isAuth && <Route path="/grooming" element={<GroomingDetails />} />}
        {isAuth && <Route path="/party" element={<PartytDetails />} />}
        {isAuth && <Route path="/swimming" element={<SwimmingDetails />} />}
        {isAuth && <Route path="/training" element={<TrainingDetails />} />}
      </Routes>
    </Router>
  );
}
export default App;
