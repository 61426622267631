import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { NavLink } from "react-router-dom";

import Rights from "./Rights";

function Terms() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div id="terms">
      <div id="header">
        <div className="flex flex-wrap py-2 navbar">
          <div className="w-full px-4 ">
            <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 rounded">
              <div className="container px-4 mx-auto flex  items-center justify-between">
                <div className="w-full relative flex justify-between lg:w-auto px-4 lg:static lg:block lg:justify-start">
                  {/* <a
                  className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-white"
                  href="#hero"
                >
                  <img src="./images/logo.png" alt="" />
                </a> */}
                  <NavLink
                    to="/"
                    className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-white"
                  >
                    <img src="./images/logo.png" alt="Logo" />
                  </NavLink>
                  {/* <button
                    className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                    type="button"
                    onClick={() => setMenuOpen(!menuOpen)}
                  >
                    <FaBars className="nb-fabars" />
                  </button> */}
                </div>
                <NavLink to="/" className="home-icon">
                  <img src="./images/terms-home.svg" alt="HomeBtn" />
                </NavLink>
                {/* <div
                  className={
                    "lg:flex flex-grow items-center" +
                    (menuOpen ? " flex" : " hidden")
                  }
                  id="example-navbar-info"
                >
                  <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                    <li className="nav-item">
                      <a
                        className="px-3 py-2 flex items-center text-md uppercase font-bold leading-snug text-black
                      hover:text-[#ff6b63]"
                        href="#services"
                      >
                        Services
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="px-3 py-2 flex items-center text-md uppercase font-bold leading-snug text-black hover:text-[#ff6b63]"
                        href="#event"
                      >
                        Gallery
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="px-3 py-2 flex items-center text-md uppercase font-bold leading-snug text-black hover:text-[#ff6b63]"
                        href="#about"
                      >
                        About Us
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="px-3 py-2 flex items-center text-md uppercase font-bold leading-snug text-black hover:text-[#ff6b63]"
                        href="#partner"
                      >
                        Franchise
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="px-3 py-2 flex items-center text-md uppercase font-bold leading-snug text-black hover:text-[#ff6b63]"
                        href="#location"
                      >
                        Locations
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="px-3 py-2 flex items-center text-md uppercase font-bold leading-snug text-black hover:text-[#ff6b63]"
                        href="#contact"
                      >
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div className="terms-header w-full h-[200px] flex items-center justify-between">
        <h1 className="ml-[50px] md:ml-[100px] text-5xl">
          Terms and Conditions
        </h1>
        <img
          className="mr-[50px] md:mr-[100px] w-16 md:w-32"
          src="./images/terms.png"
          alt="Terms and Conditions"
        />
      </div>
      <div className="flex items-center justify-center">
        <div className="mt-[100px] mb-[200px] terms-content w-[700px]">
          <p className="px-10 text-base lg:text-lg text-justify">
            Welcome to our website. If you continue to browse and use this
            website you are agreeing to comply with and be bound by the
            following terms and conditions of use, which together with our
            privacy policy govern Pupparazzi relationship with you in relation
            to this website. The term ‘Pupparazzi‘ or ‘us’ or ‘we’ refers to the
            owner of the website who is ‘Pupparazzi Dog Cafe LLP’(hereinafter
            referred to as “Owner”). The term ‘you’ refers to the user or viewer
            of our website.
          </p>
          <p className="mt-5 px-10 text-base lg:text-lg text-justify">
            THE USE OF THIS WEBSITE IS SUBJECT TO THE FOLLOWING TERMS OF USE:
            The content of the pages of this website is for your general
            information and use only. It is subject to change without notice.
            Unauthorized use of this website may give rise to a claim for
            damages and/or be a criminal offense. You may not create a link to
            this website from another website or document without THE owner’s
            prior written consent. Your use of this website and any dispute
            arising out of such use is subject to the laws of India or other
            regulatory authority.
          </p>
        </div>
      </div>
      <footer className="flex items-center justify-between">
        <div className="p-7  h-full  footer-content1">
          <div className="flex justify-center items-center mb-3 footer-logo">
            <NavLink to="/">
              <img
                className="w-[100%]"
                src="./images/footer-logo2.png"
                alt="Logo"
              />
            </NavLink>
          </div>
          {/* <div className="footer-row">
            <div className="footer-col address">
              <img src="./images/pin.png" alt="" />
              <a
                href="https://maps.google.com/?cid=11204830985246932760&entry=gps"
                target="_blank"
              >
                <h3 className="w-[200px] md:w-[300px] text-xs md:text-base lg:text-lg">
                  Next Crossroad to Bharat Petroleum, VIP Rd, opp. Stanza, South
                  Bopal, Ahmedabad, Gujarat 380058
                </h3>
              </a>
            </div>
            <div className="footer-col phone">
              <img src="./images/phone.png" alt="" />
              <a href="tel:6358848177">
                <h3 className="w-[200px] md:w-[300px] text-xs md:text-base lg:text-lg">
                  63588 48177
                </h3>
              </a>
            </div>
            <div className="footer-col mail">
              <img src="./images/mail.png" alt="" />
              <a href="mailto:info@pupparazziclub.com">
                <h3 className="w-[200px] md:w-[300px] text-xs md:text-base lg:text-lg">
                  info@pupparazziclub.com
                </h3>
              </a>
            </div>
          </div>
          <div className="w-full footer-row">
            <div className="flex flex-col ">
              <div>
                <h2 className=" text-base md:text-lg lg:text-xl ">KNOW MORE</h2>
              </div>
              <div className="flex flex-row justify-between">
                <div>
                  <a href="#services">
                    <h3 className=" text-xs md:text-base lg:text-lg">
                      Services
                    </h3>
                  </a>
                </div>
                <div>
                  <a href="#event">
                    <h3 className="text-xs md:text-base lg:text-lg">Events</h3>
                  </a>
                </div>
                <div>
                  <a href="#about">
                    <h3 className="text-xs md:text-base lg:text-lg">About</h3>
                  </a>
                </div>
                <div>
                  <a href="#partner">
                    <h3 className="text-xs md:text-base lg:text-lg">
                      Franchise
                    </h3>
                  </a>
                </div>
                <div>
                  <a href="#location">
                    <h3 className="text-xs md:text-base lg:text-lg">
                      Location
                    </h3>
                  </a>
                </div>
                <div>
                  <a href="#contact">
                    <h3 className="text-xs md:text-base lg:text-lg">Contact</h3>
                  </a>
                </div>
              </div>
            </div>
          </div> */}
          <div className="w-[70%] footer-row ">
            <div className="flex flex-col">
              {/* <div>
                <h2 className="text-base md:text-lg lg:text-xl">INFORMATION</h2>
              </div>
              <div className="flex flex-row footer-col footer-info">
                <div>
                  <NavLink to="/privacy">
                    <h3 className="text-xs md:text-base lg:text-lg">
                      Privacy Policy
                    </h3>
                  </NavLink>
                </div>
                <div>
                  <NavLink to="/terms">
                    <h3 className="text-xs md:text-base lg:text-lg">
                      Terms of Use
                    </h3>
                  </NavLink>
                </div>
              </div> */}
              {/* <div className="w-[70%] footer-row">
                <h2 className="text-base md:text-lg lg:text-xl">FOLLOW</h2>
                <div className="footer-col">
                  <a
                    href="https://instagram.com/pupparazziclub?igshid=ZmVmZTY5ZGE="
                    target="_blank"
                  >
                    <img
                      className="w-[50%] md:w-[70%] lg:w-[100%]"
                      src="./images/insta.png"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://www.facebook.com/pupparazziclub/"
                    target="_blank"
                  >
                    <img
                      className="w-[50%] md:w-[70%] lg:w-[100%]"
                      src="./images/fb.png"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://youtube.com/@pupparazziclubofficial5072"
                    target="_blank"
                  >
                    <img
                      className="w-[50%] md:w-[70%] lg:w-[100%]"
                      src="./images/yt.png"
                      alt=""
                    />
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </footer>
      <div className="footer-pt w-full ">
        <Rights />
      </div>
    </div>
  );
}

export default Terms;
