import React, { useState, useEffect } from "react";
import { Carousel } from "flowbite-react";
import { storage } from "../lib/init-firebase";
import { ref, listAll, getDownloadURL } from "firebase/storage";

const Event = () => {
  const [imageList, setImageList] = useState([]);
  const imageListRef = ref(storage, "images/");

  useEffect(() => {
    listAll(imageListRef).then((res) => {
      res.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setImageList((prevImagelist) => [...prevImagelist, url]);
        });
      });
    });
  }, []);

  return (
    <div className="relative" id="event">
      <div className="absolute left-2 md:left-8 -rotate-[7deg] md:-rotate-[5deg] lg:-rotate-[10deg] -top-[80px] md:-top-[130px] lg:-top-[160px]">
        <img
          className="w-[150px] md:w-[300px] lg:w-[400px]"
          src="./images/event-bg.png"
          alt="Background"
        />
      </div>
      <div className="absolute right-2 md:right-10 md:right-20 lg:right-32 -top-[60px] md:-top-[110px] lg:-top-[120px]">
        <img
          className="w-[130px] md:w-[200px] lg:w-[300px]"
          src="./images/event-bg2.png"
          alt="Background"
        />
      </div>
      <div className="absolute bottom-20 md:-bottom-20 right-10 lg:right-48">
        <img
          className="w-[60px] md:w-[100px] lg:w-[130px]"
          src="./images/event-bg3.png"
          alt="Background"
        />
      </div>
      <div className="absolute bottom-20 left-10 md:-bottom-28 md:left-40 ">
        <img
          className="w-[70px] md:w-[150px] lg:w-[170px]"
          src="./images/event-bg4.png"
          alt="Background"
        />
      </div>
      <div className="absolute top-[20%] md:top-[12%] lg:top-[20%] md:left-[1%] lg:left-[3%]">
        <img
          className="w-[100px] md:w-[170px] lg:w-[250px]"
          src="./images/event-bg1.png"
          alt="Background"
        />
      </div>
      <div className="absolute top-[25%] md:top-[20%] lg:top-[50%] right-[6%] lg:right-[12%]">
        <img
          className="w-[50px] md:w-[70px] lg:w-[100px]"
          src="./images/event-bg5.png"
          alt="Background"
        />
      </div>
      <div className="events mt-[20px] md:mt-[10px] lg:mt-[-50px]">
        <h1 className="text-4xl md:text-5xl lg:text-6xl">
          Take your doggo out for a real treat 
        </h1>
        {/* <p className="mt-2 text-center text-xl md:text-2xl lg:text-3xl">
          We always keep our club happening with events and parties
        </p> */}
      </div>
      <div className="flex items-center justify-center mt-[-50px] md:mt-[100px] lg:mt-[50px] md:mb-[100px] carousel-outer">
        <div className=" h-[600px] w-[900px]">
          <Carousel
            indicators={false}
            slide={false}
            leftControl={
              <img
                className="w-10 opacity-70"
                src="./images/left-c.png"
                alt="left"
              ></img>
            }
            rightControl={
              <img
                className="w-10 opacity-75"
                src="./images/right-c.png"
                alt="right"
              ></img>
            }
          >
            {imageList.map((url, idx) => {
              return <img key={idx} src={url} alt="Events"></img>;
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Event;
