import React from "react";
import { Carousel } from "flowbite-react";
function Store({ closeModal }) {
  return (
    <div className="z-50 fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex items-center justify-center">
      <div className="relative bg-[#F184A6] flex flex-col tm-outer w-[350px] md:w-[750px] lg:w-[800px] h-[610px] md:h-[570px]">
        <button
          onClick={() => {
            closeModal(false);
          }}
        >
          <img
            className="absolute top-4 right-6 w-4 md:w-7"
            src="./images/sc.png"
            alt="Cancel Button"
          />
          <img
            className="absolute bottom-2 left-48 md:bottom-8 md:left-10 w-36 md:w-64"
            src="./images/ps-img.png"
            alt="Pet-Store Background"
          />
        </button>
        <div className="mt-[40px] md:m-[20px] flex items-center flex-col md:flex-row justify-center md:justify-start  tm-upper">
          <div className=" tm-img">
            <div className="h-[185px] w-[325px] md:h-[300px] md:w-[400px]">
              <Carousel
                indicators={false}
                slide={true}
                leftControl={
                  <img
                    className="w-7 opacity-70"
                    src="./images/left-c.png"
                    alt="Left"
                  ></img>
                }
                rightControl={
                  <img
                    className="w-7 opacity-75"
                    src="./images/right-c.png"
                    alt="Right"
                  ></img>
                }
              >
                <img src="./images/PetStore/petstore-1.jpg" alt="Pet-Store" />
                <img src="./images/PetStore/petstore-2.jpg" alt="Pet-Store" />
                <img src="./images/PetStore/petstore-3.jpg" alt="Pet-Store" />
                <img src="./images/PetStore/petstore-4.jpg" alt="Pet-Store" />
                <img src="./images/PetStore/petstore-5.jpg" alt="Pet-Store" />
                <img src="./images/PetStore/petstore-6.jpg" alt="Pet-Store" />
                <img src="./images/PetStore/petstore-7.jpg" alt="Pet-Store" />
                <img src="./images/PetStore/petstore-8.jpg" alt="Pet-Store" />
                <img src="./images/PetStore/petstore-9.jpg" alt="Pet-Store" />
              </Carousel>
            </div>
          </div>
          <div className="mt-2 md:mt-[-40px] mx-auto md:mx-[20px] w-[300px] md:w-[430px] tm-text">
            <h1 className="text-2xl md:text-4xl lg:text-5xl text-left">
              Pet Store
            </h1>
            <p className="text-xs lg:text-sm mb-5 text-justify">
              Your good boi deserves nothing but the best range of pet toys,
              accessories and food. Our pet stores have carefully curated and
              hand-picked products that cater to the needs of different breeds,
              age groups and health factors. Our team is always happy to assist
              you, and can also help you pick out essentials (while playing with
              your doggo, of course). Sniff sniff, and you will find us at
              multiple locations around the city!
            </p>
          </div>
        </div>
        <div className="absolute bottom-32 right-8 md:bottom-48 md:right-10 w-[250px] lg:w-[300px] text-center bg-[#19BCCD] form-col m-btn">
          <a
            onClick={() => {
              closeModal(false);
            }}
            href="#location"
          >
            <button className="w-full text-base lg:text-lg  ">Locate Us</button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Store;
