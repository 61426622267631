import React from "react";
import { Carousel } from "flowbite-react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../lib/init-firebase";

function Boarding({ closeModal }) {
  const date = new Date().valueOf();
  const [isLoading, setIsLoading] = React.useState(false);
  const [boarding, setBoarding] = React.useState({
    fname: "",
    email: "",
    phone: "",
    ageYears: "",
    breed: "",
    from: "",
    to: "",
    today: date,
  });

  function handleChange(event) {
    const { name, value } = event.target;
    setBoarding((prevBoarding) => ({
      ...prevBoarding,
      [name]: value,
    }));
  }

  const postData = async (e) => {
    e.preventDefault();
    const { fname, email, phone, ageYears, breed, from, to, today } = boarding;

    const boardingDetailsRef = collection(db, "boarding");
    if (fname && email && phone && ageYears && breed && from && to) {
      setIsLoading(true);
      addDoc(boardingDetailsRef, {
        fname,
        email,
        phone,
        ageYears,
        breed,
        from,
        to,
        today,
      })
        .then((res) => {
          console.log(res.id);
          alert(
            "Thanks for sharing the details! \nOur Team will get back to you shortly."
          );
          setIsLoading(false);
          setBoarding({
            fname: "",
            email: "",
            phone: "",
            ageYears: "",
            breed: "",
            from: "",
            to: "",
            today: 0,
          });
        })
        .catch((err) => {
          alert("Something went worng! \n Please try again after sometime.");
          setIsLoading(false);
          // console.log(err.message);
        });
    } else {
      alert("Please fill all the details !!");
    }
  };

  return (
    <div className="z-50 fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex items-center justify-center">
      <div className="relative bg-[#FF6B63] flex flex-col tm-outer w-[350px] md:w-[750px] lg:w-[800px] h-[650px] md:h-[570px]">
        <button
          onClick={() => {
            closeModal(false);
          }}
        >
          <img
            className="absolute top-4 right-6 w-4 md:w-7"
            src="./images/bc.png"
            alt="Cancel Button"
          />
          <img
            className="absolute bottom-[35%] md:bottom-16 right-2 w-28 md:w-40 lg:w-52"
            src="./images/bm-img.png"
            alt=""
          />
        </button>
        <div className="mt-[40px] md:m-[20px] flex items-center flex-col md:flex-row justify-center md:justify-start  tm-upper">
          <div className=" tm-img">
            <div className="h-[185px] w-[325px] md:h-[300px] md:w-[400px]">
              <Carousel
                indicators={false}
                slide={true}
                leftControl={
                  <img
                    className="w-7 opacity-70"
                    src="./images/left-c.png"
                    alt="Left"
                  ></img>
                }
                rightControl={
                  <img
                    className="w-7 opacity-75"
                    src="./images/right-c.png"
                    alt="Right"
                  ></img>
                }
              >
                <img src="./images/Boarding/Boarding-1.jpg" alt="Boarding" />
                <img src="./images/Boarding/Boarding-2.jpg" alt="Boarding" />
                <img src="./images/Boarding/Boarding-3.jpg" alt="Boarding" />
                <img src="./images/Boarding/Boarding-4.jpg" alt="Boarding" />
                <img src="./images/Boarding/Boarding-5.jpg" alt="Boarding" />
                <img src="./images/Boarding/Boarding-6.jpg" alt="Boarding" />
                <img src="./images/Boarding/Boarding-7.jpg" alt="Boarding" />
                <img src="./images/Boarding/Boarding-8.jpg" alt="Boarding" />
              </Carousel>
            </div>
          </div>
          <div className="mx-auto md:mx-[20px] w-[300px] md:w-[430px] tm-text">
            <h1 className="text-2xl md:text-4xl lg:text-5xl text-left">
              Boarding
            </h1>
            <p className="text-xs lg:text-sm mb-5 text-justify leading-[14px]">
              Home is a four letter word, and we make sure your four-legged
              friends feel at home while you’re away. Our staff is extremely
              gentle and trained, so there is nothing to worry about! We offer a
              luxurious stay with a fully air conditioned closed space, along
              with a huge open area for them to play around. The facility is
              designed to be spacious, comfortable, and hygienic while offering:
            </p>
            <ul className="mt-[-18px] ml-5 list-disc text-[#fff]">
              <li className="text-xs lg:text-sm">
                Timely curated meals as per the breed
              </li>
              <li className="text-xs lg:text-sm">
                Daily play time with 24/7 staff supervision
              </li>
              <li className="text-xs lg:text-sm">Regular check-ups</li>
            </ul>
          </div>
        </div>
        <div className="-mt-[5px] md:mt-[-20px] m-form">
          <form
            className="w-[325px] md:w-[550px] text-xs md:text-sm lg:text-base form-div"
            action=""
          >
            <div className="form-row">
              <div className=" form-col">
                <input
                  required
                  placeholder="Name"
                  className=" text-xs lg:text-sm h-[35px] w-[300px] md:h-[35px] md:w-[550px]"
                  type="text"
                  name="fname"
                  value={boarding.fname}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-col">
                <input
                  required
                  placeholder="Email"
                  className="text-xs lg:text-sm h-[35px] md:h-[35px] w-[135px] md:w-[260px]"
                  type="email"
                  name="email"
                  value={boarding.email}
                  onChange={handleChange}
                />
              </div>
              <div className="form-col">
                <input
                  required
                  placeholder="Phone Number"
                  className="text-xs lg:text-sm h-[35px] md:h-[35px] w-[135px] md:w-[260px]"
                  type="number"
                  name="phone"
                  value={boarding.phone}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-col">
                <select
                  name="ageYears"
                  value={boarding.ageYears}
                  onChange={handleChange}
                  required
                  className=" text-xs lg:text-sm h-[35px] md:h-[35px] w-[135px] md:w-[260px]"
                >
                  <option value="" disabled defaultValue>
                    Years
                  </option>
                  <option value="less than 1">Less than 1</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15 & Above"> 15 & Above</option>
                </select>
              </div>
              {/* <div className="form-col">
                <select
                  name="ageMonth"
                  value={boarding.ageMonth}
                  onChange={handleChange}
                  required
                  className="ml-[-15px] md:ml-0 text-xs lg:text-sm h-[22px] md:h-[35px] w-[50px] md:w-[115px]"
                >
                  <option value="" disabled selected>
                    Month
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
              </div> */}

              <div className="form-col">
                <input
                  required
                  placeholder="Dog Breed"
                  className="text-xs lg:text-sm h-[35px] md:h-[35px] w-[135px] md:w-[260px]"
                  type="text"
                  name="breed"
                  value={boarding.breed}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-col">
                <input
                  min={new Date().toISOString().split("T")[0]}
                  required
                  placeholder="From"
                  type="date"
                  name="from"
                  value={boarding.from}
                  onChange={handleChange}
                  className="text-[10px] lg:text-sm h-[35px] md:h-[35px] w-[130px] md:w-[250px]"
                />
              </div>
              <p className="mt-2 md:mt-2 text-xs lg:text-sm  text-white">to</p>
              <div className="form-col">
                <input
                  min={new Date().toISOString().split("T")[0]}
                  required
                  placeholder="Till"
                  type="date"
                  name="to"
                  value={boarding.to}
                  onChange={handleChange}
                  className="text-[10px] lg:text-sm h-[35px] md:h-[35px] w-[130px] md:w-[250px]"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="bg-[#F8BF53] form-col m-btn">
                <button
                  className=" text-base lg:text-lg w-[300px] h-[30px] md:w-[550px] md:h-[40px]"
                  type="submit"
                  onClick={postData}
                  disabled={isLoading}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Boarding;
